.Project-Booking-Selected-Customer-Location-Table {
    width: 90%;
    min-height: 100px;

    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-wrap: wrap;
    align-content: flex-start;
}


.Project-Booking-Customer-Selection {
    width: 90%;
    min-height: 200px;

    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: wrap;
    border: 1px solid gray;
    align-content: flex-start;
}

.Project-Booking-Customer-Selection-Header {
    display: flex;
    flex-direction: row;

    background-color: rgb(215, 215, 215);
    border: 1px solid black;
}

.Project-Booking-Customer-Selection-Header-Column {
    border-right: 1px solid black;
    margin-left: 2px;
}

.Project-Booking-Customer-Selection-Column {
    margin-left: 2px;
}

.Project-Booking-Customer-Selection-Row {
    display: flex;
    flex-direction: row;
}

.Project-Booking-Customer-Selection-Row:hover {
    background-color: rgb(236, 236, 236);
    cursor: pointer;
}

.Project-Booking-Selected-Customer-Window {

}