.Customer-Request-Window {
    width: 100%;
    height: 100%;
}

.Customer-Request-Header {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Customer-Request-Top-Header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}

.Customer-Request-Bottom-Header {
    width: 95%;
    height: 25px;
    border-bottom: 2px solid rgba(6, 89, 79,0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 60px;
    margin-top: 20px;
    font-size: 0.9em;
}

.Header-Title {
    color: gray;
    cursor: pointer;
}

.Customer-Request-Header-Title {
    width: 25%;
    min-width: 200px;
}

.Customer-Request-Header-Search {
    margin-left: 30px;
    width: 440px;
    max-width: 90vw;
    height: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid gray;
    border-radius: 8px;

}

.Customer-Request-Header-Search-List {
    position: absolute;
    top: 5.5%;
    width: 440px;
    height: 200px;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: white;
    overflow: hidden;
}

.Customer-Request-Header-Search-Term {
    padding-left: 20px;
    cursor: pointer;
}

.Customer-Request-Header-Search-Term:hover {
    background-color: rgb(219, 219, 219);
}

.Customer-Request-Header-Search-Icon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
    color: gray;
    cursor: pointer;
}

.Customer-Request-Header-Search-Text {
    width: 90%;
    border: none;
    margin-left: 10px;
}

.Customer-Request-Header-Search-Text:focus {
    outline: none;
}

.Customer-Request-Header-Controls {
    margin-left: 2px;
    width: 300px;
    max-width: 90vw;
    margin-right: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.Customer-Request-Header-All-Button {
    background-color: rgb(6, 89, 79);
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 10px;
    padding: 8px 12px;
}

.Customer-Request-Header-Active {
    border: 1px solid gray;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    margin-right: 20px;
}

.Customer-Request-Header-Select {
    padding: 5px;
    cursor: pointer;
}

.Selected-Active {
    background-color: rgba(6, 89, 79,0.4);
}

.Customer-Request-Header-Select-Divider {
    border-right: 1px solid gray;;
}

.Customer-Request-Display {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    overflow-y: auto;
    height: 85%;
    padding-left: 2%;
    width: 100%;
}

.Customer-Request-No-Display {
    width: 100%;
    height: 100%;
    text-align: center;
    text-justify: center;
}

.Vender-Request-Tile {
    width: 98%;
    height: 55px;
    border-bottom: 2px solid rgba(6, 89, 79,0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
}

.Customer-Request-Tile-Image {
    width: 20px;
    height: 20px;
    color: rgb(1, 166, 255);
}

.Customer-Request-Tile-Information {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 10px;
    font-size: 0.9em;
}

.Customer-Request-Tile-Data {
    width: 26%;
    min-width: 120px;
}

.Customer-Request-Date {
    width: 17%;
}

.Customer-Request-Flag-Frame {
    width: 30px;
}
.Customer-Request-Flag {
    width: 100%;
    color: rgba(255, 0, 0, 0.5);
}

.Customer-Request-Flag-Checked {
    width: 30px;
    color: rgba(255, 0, 0, 0.5);
}

.Customer-Request-Flag-Checked:hover {
    color: rgb(183, 179, 179);
}

.Customer-Request-Flag-Unchecked {
    width: 30px;
    color: rgb(183, 179, 179);
}

.Customer-Request-Flag-Unchecked:hover {
    color: gray;
}

.Customer-Request-Record-Window {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow-y: scroll;
}

.Customer-Request-Record-Header {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(6, 89, 79);
    margin-bottom: 15px;
    border-left: solid 2px rgba(255, 255, 255, 0.271);
    padding-left: 20px;
}

.Customer-Request-Record-Header-Close {
    color:black;
    width: 35px;
    height: 35px;
    margin-right: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.Customer-Request-Button {
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 10px;
    cursor: pointer;
    height: 30px;
    background-color: transparent;
    border: none;
    color: white;
    border-radius: 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    margin-left: 2px;
    font-size: 1em;
}

.Customer-Request-Button-End {
    margin-left: auto;
    margin-right: 20px;
}

.Customer-Request-Active {
    background-color: rgb(6, 89, 79);
    color: white;
    margin-right: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.Customer-Request-Confirmation {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Customer-Request-Confirmation-Header {
    font-size: 1.2em;
}

.Customer-Request-Confirmation-Button-Group {
    margin-top: 20px;
}

.Customer-Request-Confirmation-Button {
    padding: 8px;
    margin-right: 20px;
    cursor: pointer;
    font-size: 1.2em;
}

.Customer-Request-Confirm-Button {
    background-color: rgb(0, 137, 0);
    color: white;
}

.Customer-Request-Deny-Button {
    background-color: rgb(255, 0, 0);
    color: white;
}

.Customer-Request-Record-Form {
    width: 95%;
    max-width: 700px;
    padding-left: 5%;
    margin-bottom: 40px;
}

.Customer-Request-Record-Form-Field {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 2px;
}

.Customer-Request-Record-Contact-Form-Field {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 2px;
    width: 250px;
}

.Customer-Request-Record-Form-Field-Section {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
}

.Customer-Request-Record-Form-Input {
    border: none;
    border-bottom: 2px solid black;
    font-size: 1em;
}

.Customer-Request-Record-Form-Input-Title {
    margin-top: -3px;
    font-size: .7em;
    font-weight: 500;
}

.Customer-Request-Record-Form-Field-Title {
    margin-right: 10px;
    color: rgba(6, 89, 79, 1);
    font-weight: 600;
}

.Customer-Request-Record-Form-Field-Value {
    font-size: 1.1em;
}

.Customer-Request-Record-Form-Field-Spacer {
    width: 100%;
}

.Customer-Request-Record-Form-Field-Card {
    width: 150px;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.Customer-Request-Record-Form-Field-Input {
    border: none;
    border-bottom: 2px solid black;
    font-size: 1em;
}



.Customer-Request-Record-Form-Under {
    display: flex;
    flex-direction: column;
    margin-bottom: 2px;
}

.Customer-Request-Record-Spacer {
    margin-top: 20px;
}

.Customer-Request-Record-Files {
    width: 310px;
    height: 500px;
    margin-left: 50px;
    margin-top: 15px;
    border: 1px solid rgb(200, 200, 200);
    border-radius: 10px;
    padding: 10px;
}

.Customer-Request-Record-Files-Title {
    text-align: center;
    font-size: 1.1em;
}

.Customer-Request-Record-Files-List {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
}

.Customer-Request-Record-File {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 33%;
    margin-top: 5px;
}

.Customer-Request-Record-File-Image {
    width: 60px;
    height: 60px;
    color:rgba(181, 2, 2, 0.8)
}

.Customer-Request-Record-File-Name {
    width: 80px;
    font-size: 0.7em;
    text-align: center;
}

.Customer-Request-Record-Signature {
    margin-top: 20px;
    margin-bottom: 50px;
    width: 200px;
}

.Customer-Request-Record-Pdf-Viewer-Window {
    position: absolute;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Customer-Request-Record-Pdf-Exit-Bar {
    height: 40px;
    width: 98%;
    padding-right: 5%;
    display: flex;
    justify-content: flex-end;
}

.Customer-Request-Record-Pdf-Exit {
    width: 35px;
    height: 35px;
    color: black;
    cursor: pointer;
    margin-top: 20px;
}

.Customer-Request-Record-Pdf-Viewer {
    width: 80%;
    max-width: 1100px;
    height: 100%;
    background-color: white;
    border: 2px solid rgb(203,203,203);
}

.Customer-Request-Rejection-Window {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Customer-Request-Rejection-Text {
    font-size: 1em;
    margin-bottom: 20px;
}

.Customer-Request-Rejection-Sections {
    margin-top: 10px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Customer-Request-Rejection-Section {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
}

.Customer-Request-Dropdown {
    width: var(--inputWidth);
    max-width: 50vw;
    font-family: monospace;
    font-size: 1em;
    border: none;
    border-bottom: 2px solid black;
}

.Customer-Request-Selection-Section {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.Customer-Request-Approval {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Customer-Request-Approval-Symbol {
    width: 80px;
    height: 80px;
    color: rgb(6, 89, 79);
}

.Customer-Request-Approval-Text {
    margin-top: 20px;
    font-size: 1.1em;
}