.New-Customer-Window {
    
}


.External-Information-Section {
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 7px;
}

.External-Customer-Type-Error{
    width: 100%;
    text-align: center;
    font-size: .9em;
    color: red;
    margin-top: 5px;
}
.External-Company-Section{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.External-Owners-Section{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Form-Shipping-Button{
    margin-top: 20px;
    padding: 5px;
    border-radius: 10px;
    background-color: rgb(6, 89, 79);
    color: white;
}

.External-Form-Shipping-Button:hover {
    cursor: pointer;
    filter: brightness(110%);
}

.External-Owners-Or-Officers-Information{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
    padding-bottom: 7px;
}

.External-Bank-Reference-Section{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
    padding-bottom: 7px;
}

.External-Bank-Reference-Title{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Trade-Reference-Title{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Trade-Reference-Information{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
    padding-bottom: 7px;
}

.External-Conditions-Title{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Conditions-Section{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
    padding-bottom: 7px;
}

.External-Conditions-Informmation{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
   
}

.External-Customer-Type-Title-External-Customer-Bold {
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Tax-Form-Title{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Form-Input-Title{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    font-size: large;
}

.External-Form-Input-Title-Row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-align: start;
    font-size: large;
    width: 90%;
    text-decoration: underline;
}

.External-Customer-W9{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Customer-Type-Title{

}

.External-Customer-ACH-Title{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219)
}

.External-Customer-Contact-Group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.External-Customer-Contact-Button {
    margin-top: 20px;
    padding: 5px;
    border-radius: 10px;
    
}

.External-Customer-Contact-Button:hover {
    cursor: pointer;
    filter: brightness(110%);
}

.External-Customer-Trade-Reference-Button{
    margin-top: 20px;
    padding: 5px;
    border-radius: 10px;

}

.External-Customer-Trade-Reference-Button:hover{
    cursor: pointer;
    filter: brightness(110%);
}

.Button-Add {
    background-color: rgb(6, 89, 79);
    color: white;
}

.Button-Remove {
    background-color: rgb(160, 73, 73);
    color: white;
}

.External-Trade-Group{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.External-Customer-Submit {
    margin-top: 20px;
    background-color: rgb(6, 89, 79);
    color: white;
    font-size: 1.4em;
    padding: 10px;
    border-radius: 5px;
}

.External-Customer-Button {
    cursor: pointer;
}

.External-Required-Files{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
}

.External-Customer-Required-Files-Title{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Customer-Card-Information{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-style: solid;
    border-width: 2px;
    padding-bottom: 7px;
}

.External-Customer-Card-Title{
    width:100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    border-bottom: solid;
    border-bottom-width: 2px;
    background-color: rgb(215, 250, 219);
}

.External-Card-Information-Group{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.External-Form-Input-Row-Files {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    justify-content: center;
}

.External-Customer-Title {
    width: 90%;
    font-size: 1.3em;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px;
    color: rgb(6, 89, 79);
}

.External-Terms-Text {
    width: 80%;
    margin-top: 10px;
}

.External-Form-Blank-Button {
    height: 22px;
    margin-right: 5px;
}